
import {CountUp} from 'countup.js';

export default {
  data: () => {
    return {
      horisontal: true,
      tickerAntall: null,
      tickerSum: null
    }
  },
  props: {
    markers: Array
  },
  computed: {
    loading() {
      return !this.markers || this.markers.length === 0;
    },
    antall() {
      return this.markers.length;
    },
    sum() {
      return this.markers.map((tildeling) => {
        return tildeling.sum;
      }).reduce((a, b) => a + b, 0);
    }
  },
  watch: {
    antall: function(value) {
      this.tickerAntall.update(value)
    },
    sum: function(value) {
      this.tickerSum.update(value)
    }
  },
  methods: {
    checkHorisontal() {
      // console.log(this.$refs.basicStats.clientWidth);
      this.horisontal = this.$refs.basicStats.clientWidth > 340;
    },
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.checkHorisontal)
    this.tickerAntall = null;
    this.tickerSum = null;
  },
  mounted() {
    // console.log('mounted')
    window.addEventListener('resize', this.checkHorisontal)
    this.tickerAntall = new CountUp(this.$refs.antall, this.antall, {
      duration: 1,
      separator: ' ',
      enableScrollSpy: true
    });
    this.tickerAntall.start()
    this.tickerSum = new CountUp(this.$refs.sum, this.sum, {
      duration: 1,
      separator: ' ',
      enableScrollSpy: true
    });
    this.tickerSum.start()
    this.$nextTick(() => {
      this.checkHorisontal();
    });
  },
}
