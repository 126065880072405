
export default {
  props: {
    markers: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: "5 siste tildelinger"
    },
    antall: {
      type: Number,
      default: 5
    }
  },
  methods: {
    randomRow(fra, til) {
      return Math.floor(Math.random() * til) + fra;
    },
  },
  computed: {
    loading() {
      return this.markers.length === 0;
    },
    topptildeling() {
      if (this.loading) {
        return [];
      }
      const filtrert = [...this.markers].sort((a, b) => {
        return b.timestamp - a.timestamp;
      });
      return filtrert.slice(0, this.antall)
    },
  }
}
