
import MiniSearch from 'minisearch';
import {mapState} from 'vuex';

export default {
  name: 'regnskapet',
  props: {
    block: Object,
    required: true
  },
  data: function() {
    return {
      filteredMarkers: [],
      minisearch: null,
      loading: true,
      query: null,
      tekstKritterier: null
    }
  },
  computed: {
    ...mapState({
      markers: state => state.tildeling,
      isDev: state => state.isDev,
      kategorier: state => state.kategorier,
      kommuner: state => state.kommuner,
      sparekonto: state => state.sparekonto
    }),
    sumSparekonto() {
      return this.sparekonto.tall.reduce(function(total, item) {
        return total + item.sum;
      }, 0);
    },
    kartUrl() {
      let str = "";
      if (this.query) {
        for (let key in this.query) {
          if (str !== "") {
            str += "&";
          }
          str += key + "=" + encodeURIComponent(this.query[key]);
        }
        str = "?" + str;
      }

      return "/tildeling" + str;
    }
  },
  mounted() {
    // console.log('mounted');
    if (this.markers.length) {
      if (this.isDev) {
        console.log('hent fra store');
      }
      this.initSearch();
    } else {
      if (this.isDev) {
        console.log('dispatch');
      }
      this.$store.dispatch('setTildeling').then(() => {
        this.initSearch()
      });
    }
  },
  methods: {
    initSearch() {
      this.minisearch = new MiniSearch({
        fields: ['navn', 'tittel', 'beskrivelse'], // fields to index for full-text search
        storeFields: ['id'] // fields to return with search results
      });
      setTimeout(() => {
        this.sok();
      }, 1000);
    },
    sok() {
      let query = {},
        tekstKritterier = '';
      const kriterier = this.block.kritterier;
      if (this.isDev) {
        console.log('sok', kriterier);
      }

      let markers = [];

      if (!kriterier.tekst) {
        markers = this.markers;
      } else {
        if (this.minisearch.documentCount === 0) {
          this.minisearch.addAll(this.markers);
        }
        query.sok = kriterier.tekst;
        let ids = this.minisearch.search(kriterier.tekst, {
          prefix: true,
          fuzzy: 0.2,
        }).map(item => item.id);
        markers = this.markers.filter(function(item) {
          return ids.includes(item.id);
        })
      }


      if (kriterier.kategori) {
        tekstKritterier += this.kategorier.find(item => item.id == kriterier.kategori).navn;
        query.kategori = kriterier.kategori;
        // console.log(kriterier.kategori);
        markers = markers.filter((item) => {
          return item.kategori.id == kriterier.kategori;
        });
      }

      if (kriterier.kommune) {
        tekstKritterier += ' i ' + this.kommuner.find(item => item.id == kriterier.kommune).navn + ' kommune ';
        query.sted = kriterier.kommune;
        markers = markers.filter((item) => {
          return item.kommune.id === parseInt(kriterier.kommune);
        });
      }

      if (kriterier.range) {
        let fra = this.$moment.unix(kriterier.range[0]).format('x').toString(),
          til = this.$moment.unix(kriterier.range[1]).format('x').toString();

        query.fra = this.$moment.unix(kriterier.range[0]).startOf('month').format("MM-YYYY");
        query.til = this.$moment.unix(kriterier.range[1]).endOf('month').format("MM-YYYY");

        if (query.fra) {
          tekstKritterier += ' fra ' + this.$moment.unix(kriterier.range[0]).startOf('month').format("D MMM YYYY");
        }
        if (query.til) {
          tekstKritterier += ' til ' + this.$moment.unix(kriterier.range[1]).endOf('month').format("D MMM YYYY");
        }

        // console.log(fra, til);
        markers = markers.filter((item) => {
          return item.timestamp >= fra && item.timestamp <= til;
        });
      }
      if (tekstKritterier.length > 0) {
        this.tekstKritterier = 'Viser ' + tekstKritterier;
      }
      this.query = query;
      this.filteredMarkers = markers;
    }
  }
}
