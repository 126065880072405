
import currency from 'currency.js';
import {mapState} from 'vuex';

export default {
  props: {
    markers: Array,
  },
  data: () => {
    return {
      visChart: true,
      chart: null,
      chartData: null
    }
  },
  computed: {
    loading() {
      return false;
    },
    ...mapState({
      tall: state => state.sparekonto.tall
    }),
  },
  mounted() {
    this.initData();
    this.initChart();
  },
  methods: {
    initData() {
      const tall = [...this.tall].sort((a, b) => {
        return a.year - b.year;
      });
      this.chartData = {
        labels: tall.map(item => "'" + item.year.toString().substr(-2)),
        series: [
          tall.map(item => item.sum)
        ]
      }
    },
    initChart() {
      const options = {
        low: 0,
        axisX: {
          showGrid: false,
        },
        axisY: {
          labelInterpolationFnc: function(value, index) {
            let currencyFormated = currency(value, {symbol: '', decimal: ',', separator: '.', precision: 0}).format(),
              currencyArray = currencyFormated.split('.'),
              forkorting = '',
              tall = value / 1000000000;

            switch (currencyArray.length) {
              case 0:
              case 1:
                forkorting = "";
                break;
              // case 2:
              //   forkorting = "k.";
              //   break;
              // case 3:
              //   forkorting = "mill.";
              //   break;
              // case 4:
              default:
                forkorting = "mrd.";
                break;
            }
            const tekst = tall + ' ' + forkorting;
            // console.log(value, tekst, currencyFormated, currencyArray.length, 1000 ^ currencyArray.length)
            return tekst;
          }
        }
      };
      this.chart = new this.$chartist.Bar(this.$refs.chart, this.chartData, options);
    }
  }
}
