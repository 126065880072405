import { render, staticRenderFns } from "./Regnskapet.vue?vue&type=template&id=1d428f4e&"
import script from "./Regnskapet.vue?vue&type=script&lang=js&"
export * from "./Regnskapet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BasicStats: require('/workspace/frontend/components/infografikk/BasicStats.vue').default,TildelingKategori: require('/workspace/frontend/components/infografikk/TildelingKategori.vue').default,CtaButton: require('/workspace/frontend/components/CtaButton.vue').default,SparekontoGraf: require('/workspace/frontend/components/infografikk/SparekontoGraf.vue').default,SisteTildeling: require('/workspace/frontend/components/infografikk/SisteTildeling.vue').default})
