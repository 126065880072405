
export default {
  props: {
    markers: Array,
    title: String,
    onlyHorizontal: {
      Boolean
    }
  },

  data: function() {
    return {
      visChart: true,
      chart: null,
      horisontal: false,
      chartData: [],
      currentValue: null
    }
  },
  computed: {
    loading() {
      return this.antall === 0;
    },
    prosent() {
      let prosent = ((this.currentValue / this.totalSum) * 100);
      if (prosent.toFixed(1) < 1 && prosent !== 0) {
        return prosent.toFixed(1);
      }
      return parseInt(prosent);
    },
    totalSum() {
      return this.markers.map((tildeling) => {
        return tildeling.sum;
      }).reduce((a, b) => a + b, 0);
    },
    antallKat() {
      return this.data.length;
    },
    antall() {
      return this.markers.length;
    },
    data() {
      let data = [];
      this.markers.forEach((tildeling) => {
        let id = tildeling.kategori.id
        const found = data.find(el => el.id === id);
        if (!found) {
          data.push({
            'id': id,
            'label': tildeling.kategori.navn,
            'antall': 1,
            'sum': tildeling.sum
          });
        } else {
          found.antall++;
          found.sum += tildeling.sum;
        }
      });

      let labels = [],
        values = [];

      data.sort((a, b) => a['label'].localeCompare(b['label'])).forEach((val, index) => {
        // console.log(val);
        labels.push(val.label);
        values.push({value: val.sum, className: this.katAssets(val.id).text});
      });

      if (data.length === 0) {
        return false;
      }

      // console.log(data);
      this.chartData = {
        labels: labels,
        series: values
      };
      return data;
    }
  },
  watch: {
    chartData: function(data) {
      if (this.chart) {
        this.chart.update(data);
      } else {
        this.initChart();
      }
      this.setValue(this.antallKat === 1 ? this.totalSum : null);
    }
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.checkHorisontal)
  },
  mounted() {
    window.addEventListener('resize', this.checkHorisontal)
    this.checkHorisontal();
  },
  methods: {
    randomRow(fra, til) {
      return Math.floor(Math.random() * til) + fra;
    },
    checkHorisontal() {
      this.horisontal = this.$refs.chartWrapper.clientWidth < 280;
      // console.log(this.$refs.chartWrapper.clientWidth, this.horisontal);
    },
    setValue(val) {
      this.currentValue = val;
    },
    initChart() {
      this.chart = new this.$chartist.Pie(this.$refs.chart, this.chartData, {
        donut: true,
        showLabel: false,
        donutWidth: 30,
        plugins: [
          this.$chartist.plugins.hover({
            onMouseEnter: (item) => {
              this.setValue(parseInt(item.target.getAttribute('ct:value')));
            },
            onMouseLeave: () => {
              this.setValue(null);
            },
            triggerSelector: null
          })
        ]
      });
    }
  }
}
